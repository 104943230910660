import { useState, useRef, RefObject } from 'react'
import { useCreateCustomerMutation, useMergeCartsMutation } from '@magentoTypes'
import { useTranslation } from 'next-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import ReCAPTCHA from 'react-google-recaptcha'
import { createMagentoClient } from 'graphql/magentoClient'
import { signIn } from 'next-auth/react'
import { toast } from 'react-toastify'
import { InputMain } from 'elements/Input/InputMain'
import { useCartId } from '~/hooks/useCartId'
import { CART_ID, DEFAULT_LOCALE } from '~/config/constants'
import useLocalStorage from '~/hooks/useLocalStorage'
import { useTransferGuestToUserWishlist } from '~/lib/transferGuestToUserWishlist'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { getStoreCode } from '~/lib/getStoreCode'
import { log } from '~/lib/log'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { ButtonMain } from '~/elements/Button/ButtonMain'

const emailPattern =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

type FormData = {
  firstName: string
  lastName: string
  email: string
  password: string
  newsletter: string
  dataConsent: string
}

export const Register = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState<boolean>(false)
  const [cartId] = useCartId()
  const { dataLayer } = useGTM()
  const [storageCartId, setCartId] = useLocalStorage<string>(CART_ID, '')
  const recaptchaRef = useRef<ReCAPTCHA>()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: true, queryClient, locale: finalLocale })
  const mutationClient = createMagentoClient({ usePost: true, queryClient, locale: finalLocale })
  const [backendError, setBackendError] = useState<string>('')
  const transferGuestToUserWishlist = useTransferGuestToUserWishlist()
  const mergeMutate = useMergeCartsMutation(mutationClient, {
    onSettled: async (data, error) => {
      if (data) {
        toast.success(t('Your cart has been merged successfully'))
      }
      await queryClient.invalidateQueries()
    },
  })
  const createCustomerMutation = useCreateCustomerMutation(
    magentoClient,
    {
      onError: (err) => {
        setBackendError(err.message?.split(':')[0])
        toast.error(err.message?.split(':')[0])
        setLoading(false)
      },
      onSuccess: async (data) => {
        const email = getValues('email')
        const password = getValues('password')
        toast.success(t('Registration successful'))
        try {
          const guestId = cartId
          const response = await signIn<'credentials'>('credentials', {
            username: email,
            password,
            redirect: false,
            reCaptchaSecret: '',
            store: getStoreCode({ locale: finalLocale }),
          })
          if (response?.error && !response?.ok) {
            toast.error(response?.error?.split(':')?.[0])
          }
          if (response?.ok && !response?.error) {
            toast.success(t('Login successful'))
            reset()
            transferGuestToUserWishlist()
            setCartId('')
            await queryClient.invalidateQueries(['session', 'cartID'])
            mergeMutate.mutate({
              sourceCartId: guestId,
              destinationCartId: cartId,
            })
            await router.push('/account')
          }
          setLoading(false)
        } catch (err) {
          toast.error(t('Server error'))
          setLoading(false)
          recaptchaRef?.current?.reset?.()
        }
      },
    },
    {
      'X-ReCaptcha': recaptchaRef.current?.getValue() ?? '',
    },
  )

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      newsletter: 'yes',
      dataConsent: '',
    },
  })

  const onSubmit = handleSubmit(
    async ({ email, password, firstName, lastName, newsletter, dataConsent }) => {
      const recaptchaToken = recaptchaRef.current?.getValue()
      const register = () =>
        createCustomerMutation.mutate({
          firstName,
          lastName,
          email,
          password,
          is_subscribed: newsletter === 'yes',
        })
      setLoading(true)
      if (recaptchaToken) {
        register()
      } else {
        recaptchaRef?.current?.executeAsync().then((token) => {
          if (token) {
            register()
          }
        })
      }
      setLoading(false)
      dataLayer(DataLayerEnum.EVENT, 'sign_up', {
        method: 'account_creation',
        user_data: {
          email_address: email,
        },
      })
    },
  )

  return (
    <aside>
      {/* <ExternalAuthButtons /> */}
      <form onSubmit={onSubmit}>
        <div>
          <InputMain
            type="text"
            placeholder={t('First Name')}
            autoComplete="given-name"
            rules={{
              required: {
                value: true,
                message: t('{{val}} is required', { val: t('First Name') }),
              },
            }}
            name="firstName"
            register={register}
            errors={errors}
          />
        </div>
        <div>
          <InputMain
            type="text"
            placeholder={t('Last Name')}
            autoComplete="family-name"
            name="lastName"
            rules={{
              required: { value: true, message: t('{{val}} is required', { val: t('Last Name') }) },
            }}
            register={register}
            errors={errors}
          />
        </div>
        <div>
          <InputMain
            type="text"
            placeholder="E-Mail"
            autoComplete="email"
            name="email"
            register={register}
            rules={{
              required: { value: true, message: t('{{val}} is required', { val: 'E-Mail' }) },
              pattern: { value: emailPattern, message: t('Please enter a valid email') },
            }}
            errors={errors}
          />
        </div>
        <div>
          <InputMain
            type="password"
            name="password"
            placeholder={t('Password')}
            autoComplete="new-password"
            rules={{
              required: { value: true, message: t('{{val}} is required', { val: t('Password') }) },
              minLength: { value: 8, message: t('A minimum of 8 characters required') },
            }}
            register={register}
            errors={errors}
          />
        </div>
        <label className="mt-10 mb-2 flex bg-[#F4F4F4] p-6 lgx:mb-4 lgx:mt-8">
          <input
            className="mr-4"
            type="checkbox"
            placeholder="dataConsent"
            {...register('newsletter')}
          />

          <span className="text-p-small" dangerouslySetInnerHTML={{ __html: t('legal text') }} />
        </label>

        <p
          className="mt-3.5 mb-12 text-p-small"
          dangerouslySetInnerHTML={{ __html: t('Signup Legal Text') }}
        />
        <ErrorMessage
          errors={errors}
          name="dataConsent"
          render={({ message }) => <p>{message}</p>}
        />
        {backendError && <p className="mb-6 text-primary-red">{backendError}</p>}
        <ButtonMain disabled={loading} fullWidth type="submit" variant="Secondary">
          {loading ? t('Loading...') : t('Register')}
        </ButtonMain>
      </form>
      <ReCAPTCHA
        ref={recaptchaRef as RefObject<ReCAPTCHA>}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string}
        onError={(e) => {
          toast.error(t('We are having trouble identifying you as a human. Please try again.'))
          console.error(e)
        }}
        onExpired={() => {
          log('The token expired')
          recaptchaRef?.current?.reset()
        }}
      />
    </aside>
  )
}
